<template>
    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
            :title="displayTitle"
          >
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">{{$t('published_date')}} </small>
                <small>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ blogDetail.updatedAt | formatDate }}</small>
              </b-media-body>
            </b-media>
            <hr>
            <div
              class="blog-content"
              v-html="displayContent"
            />
            <hr class="my-2">
          </b-card>
        </b-col>
      </b-row>
    </div>

</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import moment from 'moment';


export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,

    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
    }
  },
  created() {
    this.getAdviceBlog();
  },
  watch:{

  },
  methods: {
    getAdviceBlog() {
      this.$http
          .get(`/rivojlanish/find-by-id/${this.$route.params.id}`)
          .then(response => {
            this.blogDetail = response.data;
          })
          .catch(error => {
            console.error('Axios error:', error)
          })
    },
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  },
  computed:{
    displayTitle() {
      switch (this.$i18n.locale) {
        case 'uz':
          return this.blogDetail.titleUz;
        case 'ru':
          return this.blogDetail.titleRu;
        case 'en':
          return this.blogDetail.titleEn;
        default:
          return ''; // Handle the case where the language is not recognized
      }
    },
    displayContent() {
      switch (this.$i18n.locale) {
        case 'uz':
          return this.blogDetail.contentUz;
        case 'ru':
          return this.blogDetail.contentRu;
        case 'en':
          return this.blogDetail.contentEn;
        default:
          return ''; // Handle the case where the language is not recognized
      }
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      return moment.unix(value / 1000).format('DD.MM.YYYY HH:mm:ss');
    }
  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-blog.scss';
.card-img, .card-img-top, .card-img-bottom {
  width: 50%;
  align-self: center;
  padding: 20px;
}
</style>
